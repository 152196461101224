import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faAddressBook,
    faCalendar,
    faChartLineDown,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faClock,
    faGear,
    faPenToSquare,
    faEllipsisVertical,
    faEllipsisH,
    faEnvelope,
    faFilter,
    faLockAlt,
    faLocationDot,
    faPlus,
    faCirclePlus,
    faPhone,
    faRedo,
    faMagnifyingGlass,
    faArrowRightFromBracket,
    faTicket,
    faXmark,
    faCircleXmark,
    faTrash,
    faUser,
    faUserPlus,
    faUsers,
    faPeopleArrows,
    faCalendarDay,
    faPen,
    faQuestionCircle,
    faTag,
    faArrowRight,
    faUpRightFromSquare,
    faEye,
    faArrowUpFromSquare,
    faLink,
    faSquareDollar,
    faShareNodes,
    faArrowDown,
} from '@fortawesome/pro-regular-svg-icons';

import {
    faAddressBook as fasAddressBook,
    faCalendar as fasCalendar,
    faUser as fasUser,
    faTicket as fasTicket,
    faArrowRight as fasArrowRight,
    faInfoCircle as fasInfoCircle,
    faTimesCircle as fasTimesCircle,
} from '@fortawesome/pro-solid-svg-icons';

import {
    faFacebookSquare,
    faInstagram,
    faLinkedin,
    faTelegram,
    faXTwitter,
    faWhatsapp,
    faYoutube,
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faAddressBook,
    faCalendar,
    faChartLineDown,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faClock,
    faGear,
    faPenToSquare,
    faEllipsisVertical,
    faEllipsisH,
    faEnvelope,
    faUpRightFromSquare,
    faFacebookSquare,
    faFilter,
    faInstagram,
    faLink,
    faLinkedin,
    faLockAlt,
    faLocationDot,
    faPlus,
    faCirclePlus,
    faPhone,
    faRedo,
    faMagnifyingGlass,
    faArrowRightFromBracket,
    faTicket,
    faXmark,
    faCircleXmark,
    faTrash,
    faXTwitter,
    faUser,
    faUserPlus,
    faUsers,
    faWhatsapp,
    faYoutube,
    faPeopleArrows,
    faCalendarDay,
    faPen,
    faTag,
    faArrowRight,
    fasAddressBook,
    fasCalendar,
    fasUser,
    faQuestionCircle,
    fasTicket,
    fasArrowRight,
    fasInfoCircle,
    faEye,
    faArrowUpFromSquare,
    faTelegram,
    faSquareDollar,
    fasTimesCircle,
    faShareNodes,
    faArrowDown,
);

Vue.component('Icon', FontAwesomeIcon);
