import {
    REQUEST,
    CLIENTS_FETCH,
    CLIENTS_LOW_SET,
    CLIENTS_LOW_FETCH,
    CLIENTS_ADD_CLIENT,
    CLIENTS_VOUCHERS_SET,
    CLIENTS_VOUCHERS_FETCH,
    CLIENTS_LINK_FETCH,
    CLIENTS_SET_LINK,
    CLIENTS_FETCH_NEXT_PAGE,
} from '@/constants';
import Vue from 'vue';

/**
 * @typedef {object} Client
 * @prop {number} id
 *
 * @typedef {{ status?: 'pending' | 'confirmed'; search?: string; }} QueryParams
 *
 * @typedef {object} Pagination The state of a paginated resource
 * @prop {Client[]} items
 * @prop {number} pagesLoaded: 0,
 * @prop {boolean} hasMorePages: true,
 * @prop {number} perPage: null,
 *
 * @typedef {object} APIData The `/clients` API endpoint response data
 * @prop {Client[]} data
 * @prop {APIMeta} meta
 *
 * @typedef {object} APIMeta The pagination meta-data of the API response
 * @prop {number} current_page
 * @prop {number} last_page
 * @prop {number} per_page
 *
 * @typedef {`${'all' | 'pending' | 'confirmed'}${':search' | ''}`} PagesKey
 * Key for the pages object fo the state derived from the request parameters
 */

/**
 * @param {APIData} apiData date of the API response
 * @param {QueryParams} query query parameters that were used for the client fetch
 * @returns {Pagination} a pagination object based on the API response or a default pagination object
 */
function pages(apiData = null, query = null) {
    if (apiData) {
        return {
            query,
            items: apiData.data,
            pagesLoaded: apiData.meta.current_page,
            hasMorePages: apiData.meta.current_page < apiData.meta.last_page,
            perPage: apiData.meta.per_page,
        };
    }

    return {
        query,
        items: [],
        pagesLoaded: 0,
        hasMorePages: true,
        perPage: null,
    };
}

/**
 * @param {QueryParams} clientFetchParams
 * @returns {PagesKey} the key of the pagination state based on request parameters
 */
function key(clientFetchParams) {
    const { status, search } = clientFetchParams;
    return (status || 'all') + (search ? `:search` : '');
}

export default {
    state: {
        clients: null,
        clientsRunningLow: null,
        vouchers: {},
        inviteLink: null,
        pages: {},
    },
    getters: {
        clients: (state) => (params) => state.pages[key(params)]?.items ?? [],
        hasMorePages: (state) => (params) => state.pages[key(params)]?.hasMorePages ?? [],
    },
    mutations: {
        [CLIENTS_SET_LINK](state, link) {
            state.inviteLink = link;
        },
        [CLIENTS_LOW_SET](state, clients) {
            state.clientsRunningLow = clients;
        },
        [CLIENTS_VOUCHERS_SET](state, vouchers) {
            state.vouchers = vouchers;
        },
        CLIENTS_SET_PAGES(state, { key, data }) {
            Vue.set(state.pages, key, data || pages());
        },
        /** clear pagination state for a specific key */
        CLEAR_PAGES(state, key) {
            Vue.set(state.pages, key, pages());
        },
    },
    actions: {
        async [CLIENTS_FETCH]({ commit, dispatch, state }, params) {
            if (!params) {
                return await Promise.all(
                    Object.values(state.pages).map(({ query }) => dispatch(CLIENTS_FETCH, query)),
                );
            }

            const _key = key(params);

            commit('CLEAR_PAGES', _key);

            const response = await dispatch(REQUEST, {
                url: 'clients',
                params,
            });

            if (response.status === 200) {
                commit('CLIENTS_SET_PAGES', { key: _key, data: pages(response.data, params) });
            }

            return response;
        },
        async [CLIENTS_FETCH_NEXT_PAGE]({ dispatch, commit, state }, params) {
            const pagesKey = key(params);
            /** @type {Pagination} */
            const pagination = state.pages[pagesKey];

            if (!pagination) return await dispatch(CLIENTS_FETCH, params);
            if (!pagination.hasMorePages) return;

            const url = `clients?page=${pagination.pagesLoaded + 1}`;
            /** @type {Response & { data: APIData }} */
            const response = await dispatch(REQUEST, { url, params });

            if (response.status === 200) {
                const data = pages(response.data);
                data.items = pagination.items.concat(data.items);
                commit('CLIENTS_SET_PAGES', {
                    key: pagesKey,
                    data,
                });
            }

            return response;
        },
        async [CLIENTS_LINK_FETCH]({ commit, dispatch }, params) {
            const getResponse = await dispatch(REQUEST, {
                url: 'invite-link',
                params,
            });

            if (getResponse.status !== 200) {
                return getResponse;
            }

            // if a link already exists, use it
            const link = getResponse.data.data.invite_link;
            const hasSlug = link.split('/invite').pop() !== '/';
            if (hasSlug) {
                commit(CLIENTS_SET_LINK, getResponse.data.data.invite_link);
                return getResponse;
            }

            // if no link exists, create one
            const putResponse = await dispatch(REQUEST, {
                method: 'put',
                url: 'invite-link',
            });

            if (putResponse.status !== 200) {
                return putResponse;
            }

            // use the newly created link
            if (putResponse.data.data['invite-link']) {
                commit(CLIENTS_SET_LINK, putResponse.data.data['invite-link']);
            }

            return putResponse;
        },
        async [CLIENTS_LOW_FETCH]({ commit, dispatch }, params) {
            commit(CLIENTS_LOW_SET, null);
            const response = await dispatch(REQUEST, {
                url: 'running-low',
                params,
            });
            if (response.status === 200) {
                // Flatten the running low resource to match the client structure and set defaults
                const clients = response.data.data.map((clientRunningLow) => {
                    return {
                        runningLowResourceId: clientRunningLow.id,
                        ...clientRunningLow,
                        ...clientRunningLow.client,
                        voucherType: clientRunningLow.type.id,
                        duration: clientRunningLow.duration,
                        amount: 5,
                    };
                });

                commit(CLIENTS_LOW_SET, clients);
            }
            return response;
        },
        async [CLIENTS_ADD_CLIENT]({ dispatch }, data) {
            const response = await dispatch(REQUEST, {
                method: 'post',
                url: 'clients',
                data,
            });
            if (response.status === 200) {
                dispatch(CLIENTS_FETCH);
            }
            return response;
        },
        async [CLIENTS_VOUCHERS_FETCH]({ state, rootState, commit, dispatch }) {
            commit(CLIENTS_VOUCHERS_SET, {});
            const responseVouchers = await dispatch(REQUEST, {
                url: 'vouchers',
            });

            if (responseVouchers.status !== 200) return responseVouchers;

            const vouchers = {};
            const remainingVouchers = responseVouchers.data.data;
            const durations = rootState.vouchers.durations;
            const parseVouchers = (client) => {
                vouchers[client.id] = rootState.vouchers.voucherTypes?.reduce((typesDict, type) => {
                    typesDict[type.id] = {
                        type,
                        totalCount: countVouchersByType(remainingVouchers, client.id, type.id),
                        counts: durations.reduce((durationsDict, duration) => {
                            durationsDict[duration.value] = countVouchersByTypeAndDuration(
                                remainingVouchers,
                                client.id,
                                type.id,
                                duration.value,
                            );
                            return durationsDict;
                        }, {}),
                    };
                    return typesDict;
                }, {});
            };

            state.clients?.forEach((client) => parseVouchers(client));
            state.clientsRunningLow?.forEach((client) => parseVouchers(client));
            commit(CLIENTS_VOUCHERS_SET, vouchers);

            return responseVouchers;
        },
    },
};

const countVouchersByType = (unusedVouchers, clientId, typeId) => {
    return unusedVouchers.reduce(
        (count, voucher) =>
            voucher.client_id === clientId && voucher.type?.id === typeId
                ? count + voucher.value
                : count,
        0,
    );
};

const countVouchersByTypeAndDuration = (unusedVouchers, clientId, typeId, duration) => {
    return unusedVouchers.reduce(
        (count, voucher) =>
            voucher.client_id === clientId &&
            voucher.type?.id === typeId &&
            voucher.duration === duration
                ? count + voucher.value
                : count,
        0,
    );
};
