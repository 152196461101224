import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import i18n, { localeOptions } from '@/languages';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    },
    routes: [
        {
            path: '/',
            name: 'Landing',
            component: () => import('@/pages/landing/Landing.vue'),
        },
        {
            path: '/:variant',
            beforeEnter(to, from, next) {
                const variant = to.params.variant;
                const urls = [
                    'personal-trainers-and-coaches',
                    'yoga-instructors-and-teachers',
                    'pilates-instructors',
                    'physiotherapists',
                ];
                if (!urls.includes(variant)) router.replace({ path: '/' });
                return next();
            },
            component: () => import('@/pages/landing/CustomLanding.vue'),
            props: (route) => {
                const urlToVariant = {
                    'personal-trainers-and-coaches': 'personal_trainer',
                    'yoga-instructors-and-teachers': 'yoga_instructor',
                    'pilates-instructors': 'pilates_instructor',
                    physiotherapists: 'physiotherapist',
                };
                return { variant: urlToVariant[route.params.variant] };
            },
        },
        {
            path: '**',
            beforeEnter(to, from, next) {
                next({ path: '/' });
            },
            component: () => import('@/pages/landing/Landing.vue'),
        },
    ],
});

router.beforeEach((to, _, next) => {
    let pathParts = to.path.split('/');
    let pathPrefix = pathParts.length > 1 && pathParts[1];

    if (localeOptions.includes(pathPrefix)) {
        i18n.locale = pathPrefix;
        document.documentElement.setAttribute('lang', pathPrefix);
        pathParts.splice(1, 1);
        next({ path: pathParts.join('/') });
    } else {
        next();
    }
});

router.beforeEach((to, _, next) => {
    if (to.matched.some((record) => record.meta.debugRoute)) {
        if (!store.state.config.rules.debug) {
            next({ path: '/' });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
