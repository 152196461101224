import {
    CLIENT,
    CONFIG_SET_RULES,
    CONFIG_SET_THEME,
    CONFIG_FETCH,
    REQUEST,
    THEME_STORAGE_KEY,
} from '@/constants';
import i18n from '@/languages';

export default {
    state: {
        rules: {},
        theme: CLIENT,
    },
    mutations: {
        [CONFIG_SET_RULES](state, rules) {
            state.rules = rules;
        },
        [CONFIG_SET_THEME](state, theme) {
            state.theme = theme;
            localStorage.setItem(THEME_STORAGE_KEY, theme);
        },
    },
    actions: {
        async [CONFIG_FETCH]({ commit, dispatch }) {
            const configFileName =
                process.env.NODE_ENV === 'production' ? 'config' : process.env.NODE_ENV;
            let { data } = await dispatch(REQUEST, {
                url: `config/${configFileName}.json`,
            });
            data.debug = data.debug || process.env.NODE_ENV === 'development';
            commit(CONFIG_SET_RULES, data);
        },
    },
    getters: {
        isThemeClient: (state) => {
            return state.theme === CLIENT;
        },
        baseUrl: (state) => {
            return state.rules.api.url;
        },
        getFrontUrlWithLocale: (state, getters) => {
            return `${getters.getFrontUrl}${i18n.locale}/`;
        },
        getFrontUrl: (state) => {
            return `${state.rules.front.url}`;
        },
    },
};
