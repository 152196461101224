<template>
    <div>
        <div
            v-if="isOnboarding && steps.length"
            ref="onboarding"
            tabindex="0"
            class="absolute inset-0 mb-16 flex h-auto flex-col items-center justify-center md:mb-0"
            autofocus
        >
            <transition enter-class="opacity-0" leave-to-class="opacity-0">
                <div
                    class="absolute inset-0 z-10 bg-black bg-opacity-50 px-2 transition-all duration-500"
                ></div>
            </transition>
            <div class="onboarding">
                <div v-if="!lastSlide" class="flex h-full flex-col justify-between">
                    <div v-if="steps[currentStep].title === 'welcome-slide'" class="slide">
                        <WelcomeSvg class="absolute w-2/3" />
                        <div class="z-40 mt-32 flex flex-col items-center justify-center md:mt-56">
                            <div class="inline-block md:pb-16">
                                <div class="text-xl font-bold text-black md:text-4xl">
                                    {{ $t('onboarding.welcome_to') }}
                                </div>
                                <BrandSvg
                                    class="mx-auto -translate-y-4 transform text-primary md:translate-y-0 md:scale-200"
                                />
                            </div>
                            <span class="slide-description-text">
                                {{ $t('onboarding.welcome_text') }}
                            </span>
                        </div>
                    </div>
                    <div v-if="steps[currentStep].title === 'create-event-slide'" class="slide">
                        <div class="slide-title">
                            <Icon class="slide-title__icon" :icon="['far', 'circle-plus']" />
                            {{ $t('onboarding.create_events_title') }}
                        </div>
                        <div class="slide-description-text">
                            {{ $t('onboarding.create_events_text') }}
                        </div>
                        <div class="flex flex-row flex-wrap items-center justify-center">
                            <div class="info-box">
                                <div class="info-box__icon">
                                    <Icon :icon="['far', 'clock']" />
                                </div>
                                <div class="info-box__title">
                                    {{ $t('onboarding.availability_slot') }}
                                </div>
                                <div class="hidden p-2 text-sm leading-tight md:inline">
                                    {{ $t('onboarding.availability_slot_description') }}
                                </div>
                            </div>
                            <div class="info-box">
                                <div class="info-box__icon">
                                    <Icon :icon="['far', 'user']" />
                                </div>
                                <div class="info-box__title">
                                    {{ $t('onboarding.personal_training') }}
                                </div>
                                <div class="hidden p-2 text-sm leading-tight md:inline">
                                    {{ $t('onboarding.personal_training_description') }}
                                </div>
                            </div>
                            <div class="info-box">
                                <div class="info-box__icon">
                                    <Icon :icon="['far', 'users']" />
                                </div>
                                <div class="info-box__title">
                                    {{ $t('onboarding.group_training') }}
                                </div>
                                <div class="hidden p-2 text-sm leading-tight md:inline">
                                    {{ $t('onboarding.group_training_description') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="steps[currentStep].title === 'invite-clients-slide'" class="slide">
                        <div class="slide-title">
                            <Icon class="slide-title__icon" :icon="['far', 'user']" />
                            {{ $t('onboarding.invite_clients_title') }}
                        </div>
                        <div class="slide-description-text">
                            {{ $t('onboarding.invite_clients_text') }}
                        </div>
                        <InviteClientsSvg class="w-2/3" style="max-width: 370px" />
                    </div>
                </div>
                <div v-else class="flex w-full flex-col items-center">
                    <BackgroundSvg class="absolute top-0 h-48" />
                    <ProfileSvg class="z-10 w-48 md:w-1/4" />
                    <div
                        style="box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1)"
                        class="relative mt-8 w-5/6 rounded-xl p-4 pt-24 md:mt-16 md:w-2/3 md:p-6 md:pt-24"
                    >
                        <div
                            class="absolute left-0 top-0 flex h-24 w-full flex-row items-center justify-start rounded-t-xl px-6"
                            style="box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15)"
                        >
                            <div>
                                <Avatar :profile="profile" size="small" />
                            </div>
                            <div class="ml-4 flex flex-col justify-center">
                                <div class="font-bold">
                                    {{ profile.full_name }}
                                </div>
                                <div class="text-sm text-neutral-darker">
                                    {{ profile.speciality }}
                                </div>
                            </div>
                        </div>
                        <div class="mb-4 mt-4 flex flex-row justify-start md:items-center">
                            <div
                                class="relative mr-2 mt-1 h-4 w-4 shrink-0 cursor-pointer rounded-sm border transition-all duration-100 md:mt-0"
                                :class="{
                                    'border-primary bg-primary': profile.visible,
                                    'border-neutral-lighter': !profile.visible,
                                }"
                                @click="toggle(!toggleValue)"
                            >
                                <Icon
                                    :icon="['far', 'check']"
                                    class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-xs text-white"
                                />
                            </div>
                            <div class="font-light md:text-lg">
                                {{ $t(isProfilePublic) }}
                            </div>
                        </div>
                        <div class="flex">
                            <InputText :value="publicCalendarLink" readonly />
                            <button
                                class="btn--bordered ml-2 whitespace-nowrap border-neutral-lighter"
                                @click="goToProfile"
                            >
                                {{ $t('onboarding.view_profile') }}
                                <Icon :icon="['far', 'up-right-from-square']" class="ml-1" />
                            </button>
                        </div>
                    </div>
                </div>
                <div class="flex w-full flex-col items-center">
                    <button
                        v-if="!isLastSlide && steps[currentStep].title !== 'invite-clients-slide'"
                        class="btn mb-4 mt-2"
                        @click="changeStep(currentStep + 1)"
                    >
                        {{ $t('onboarding.continue') }}
                    </button>

                    <button
                        v-if="!isLastSlide && steps[currentStep].title == 'invite-clients-slide'"
                        class="btn mb-4 mt-8"
                        @click="isLastSlide = true"
                    >
                        {{ $t('onboarding.get_started') }}
                    </button>

                    <div v-if="!lastSlide">
                        <div
                            v-for="(step, index) in steps"
                            :key="step.title"
                            :class="[
                                'mx-2 inline-block h-3 w-3 cursor-pointer rounded-full',
                                currentStep === index ? 'bg-neutral-darkest' : 'bg-neutral-lighter',
                            ]"
                            @click="changeStep(index)"
                        />
                    </div>

                    <div
                        v-if="lastSlide"
                        class="mt-10 flex w-full justify-between md:absolute md:bottom-0 md:mb-5 md:px-10"
                    >
                        <button class="btn--bordered z-10 !border-2" @click="goToProfileEdit()">
                            {{ $t('onboarding.edit_profile') }}
                        </button>
                        <button class="btn" @click="finishOnboarding">
                            {{ $t('onboarding.close') }}
                        </button>
                    </div>

                    <div v-else class="md:absolute md:bottom-0 md:right-0 md:mb-5 md:mr-16">
                        <a class="link-secondary-here link-secondary" @click="finishOnboarding">
                            {{ $t('onboarding.skip_intro') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <a tabindex="0" class="fixed top-0 overflow-hidden" @focus="$refs.onboarding.focus()" />
    </div>
</template>

<script>
import { Vue, Component, Watch } from 'vue-property-decorator';
import WelcomeSvg from '@/assets/img/onboarding-welcome.svg';
import InviteClientsSvg from '@/assets/img/onboarding-invite-clients.svg';
import BrandSvg from '@/assets/img/brand.svg';
import BackgroundSvg from '@/assets/img/onboarding-background.svg';
import ProfileSvg from '@/assets/img/onboarding-profile.svg';
import InputText from '@/components/input/InputText';
import InputToggle from '@/components/input/InputToggle';
import {
    ONBOARDING_SHOW_STATE_SET,
    ONBOARDING_SLIDE_SET,
    ONBOARDING_STORAGE_KEY,
    PROFILE_ONBOARDING_UPDATE,
    PROFILE_TOGGLE_VISIBILITY,
} from '@/constants';
import Avatar from '@/components/general/Avatar.vue';

@Component({
    components: {
        Avatar,
        InputToggle,
        WelcomeSvg,
        InviteClientsSvg,
        BrandSvg,
        BackgroundSvg,
        ProfileSvg,
        InputText,
    },
})
export default class Onboarding extends Vue {
    steps = [
        {
            title: 'welcome-slide',
        },
        {
            title: 'create-event-slide',
        },
        {
            title: 'invite-clients-slide',
        },
    ];

    currentStep = 0;
    lastSlide = false;
    isLoadingHappened = false;
    isProfilePublic = this.profile.visible
        ? 'profile.public.visible'
        : 'profile.public.not_visible';
    toggleValue = this.profile.visible;

    @Watch('isCalendarLoading', { immediate: false })
    checkIfLoading() {
        //show OnBoarding only for professionals
        if (this.isThemeClient) return;

        //if the calendar is not loading anymore then
        //it means that the first calendar loading is done and we can decide to show onboarding or not
        if (!this.isCalendarLoading) {
            //if length of events and slots array are 0 and onboarding was not shown
            //then it means that user is new and onboarding needs to be displayed
            const onBoardingHappened = localStorage.getItem(ONBOARDING_STORAGE_KEY) === 'true';
            if (
                this.getCalendarEvents.length === 0 &&
                this.getCalendarSlots.length === 0 &&
                !onBoardingHappened
            ) {
                this.$store.commit(ONBOARDING_SHOW_STATE_SET, true);
            }
        }
    }

    tryActivateOnboard() {
        if (!this.areEventsPresent && !this.areSlotsPresent) {
            this.$store.commit(ONBOARDING_SHOW_STATE_SET, true);
        }
    }

    mounted() {
        //localStorage.setItem(ONBOARDING_STORAGE_KEY, false);
    }

    changeStep(newStep) {
        this.$store.commit(ONBOARDING_SLIDE_SET, this.steps[newStep].title);
        this.currentStep = newStep;
    }
    async finishOnboarding() {
        localStorage.setItem(ONBOARDING_STORAGE_KEY, true);
        this.$store.commit(ONBOARDING_SLIDE_SET, null);
        this.$store.commit(ONBOARDING_SHOW_STATE_SET, false);

        await this.$store.dispatch(PROFILE_ONBOARDING_UPDATE);
    }

    goToProfile() {
        this.finishOnboarding();
        this.$router.push({ path: `/@${this.profile.username}` });
    }

    goToProfileEdit() {
        this.$router.push({
            name: this.isThemeClient ? 'ClientProfilePersonal' : 'ProfessionalProfileProfessional',
        });
        this.finishOnboarding();
    }

    toggle(value) {
        this.toggleValue = value;
        //This could cause some problems with if the profile is currently public, since the toggle starts out on not public
        this.$store.dispatch(PROFILE_TOGGLE_VISIBILITY);
        this.isProfilePublic = value ? 'profile.public.visible' : 'profile.public.not_visible';
    }

    get isLastSlide() {
        return this.lastSlide;
    }

    set isLastSlide(state) {
        if (state === true) {
            this.$store.commit(ONBOARDING_SLIDE_SET, null);
        }
        this.lastSlide = state;
    }

    get isThemeClient() {
        return this.$store.getters.isThemeClient;
    }

    get getCalendarEvents() {
        return this.$store.state.calendar.events;
    }

    get getCalendarSlots() {
        return this.$store.state.calendar.slots;
    }

    get isCalendarLoading() {
        console.log('watch: ' + this.$store.getters.isCalendarLoading);
        return this.$store.getters.isCalendarLoading;
    }

    get isOnboarding() {
        return this.$store.state.onboarding.show;
    }

    get profile() {
        return this.$store.state.profile.user?.professional;
    }

    get publicCalendarLink() {
        return `${window.location.origin}/@${this.profile.username}`;
    }
}
</script>

<style lang="postcss" scoped>
.onboarding {
    @apply relative z-30 mx-4 flex flex-col items-center overflow-scroll rounded bg-white p-6 shadow;
}

.btn-view-profile {
    @apply relative rounded-l-none;
    left: -0.5rem;
}

.info-box {
    @apply m-2 flex h-20 w-20 flex-col justify-center rounded-lg border-2 border-primary p-2 text-sm;
}

.info-box__icon {
    @apply flex flex-col items-center justify-center text-2xl text-primary;
}
.info-box__title {
    @apply my-2 text-sm leading-4;
}

.slide {
    @apply flex flex-col items-center justify-between text-center;
}

.btn,
.btn--bordered,
.link-secondary-here {
    @apply text-sm normal-case;
}

.slide-title {
    @apply pb-4 pt-8 text-xl font-bold text-primary;
}

.slide-title__icon {
    @apply mr-1 text-lg;
}

.slide-description-text {
    @apply text-sm font-medium;
}

.separator {
    @apply my-4 h-px w-full bg-neutral-lighter;
}

@screen sm {
    .slide-description-text,
    .btn,
    .btn--bordered,
    .link-secondary-here {
        @apply text-base;
    }
}

@screen md {
    .onboarding {
        width: 44rem;
        height: 40rem;
    }

    .skip-intro-btn {
        position: absolute;
        right: 0;
        margin-right: 4rem;
    }

    .slide-title {
        @apply text-4xl;
    }

    .slide-title__icon {
        @apply text-3xl;
    }

    .slide-description-text {
        @apply mb-6 max-w-xl text-lg;
    }

    .info-box {
        @apply h-48 w-48;
    }

    .info-box__icon {
        @apply mb-2 text-4xl text-primary;
    }

    .info-box__title {
        @apply text-lg;
    }
}
</style>
