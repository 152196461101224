import {
    INVITE_ACCEPT,
    INVITE_REG_SET,
    INVITE_FETCH_PROFESSIONAL,
    INVITE_REMOVE,
    INVITE_SET,
    INVITE_SET_PROFESSIONAL,
    REQUEST,
    INVITE_CLIENT_SET,
    VALIDATION_CREATE_MESSAGE,
} from '@/constants';

export default {
    state: {
        slug: null,
        professional: null,
        client: null,
        registerURL: null,
    },
    getters: {
        hasPendingInvite: (state) => state.slug !== null,
    },
    mutations: {
        [INVITE_SET](state, slug) {
            state.slug = slug;
        },
        [INVITE_REMOVE](state) {
            state.slug = null;
            state.professional = null;
            state.professional_id = null;
            state.client = null;
            state.client_id = null;
            state.registerURL = null;
        },
        [INVITE_REG_SET](state, registerURL) {
            state.registerURL = registerURL;
        },
        [INVITE_CLIENT_SET](state, client) {
            state.client = client;
        },
        [INVITE_SET_PROFESSIONAL](state, professional) {
            state.professional = professional ?? null;
        },
    },
    actions: {
        async [INVITE_FETCH_PROFESSIONAL]({ state, commit, dispatch, rootGetters }) {
            if (!state.slug) {
                console.warn('Tried fetching inviting professional with no pending invitations');
                return;
            }

            const response = await dispatch(REQUEST, {
                method: 'get',
                url: `guest/invite-link/${state.slug}`,
                baseURL: rootGetters.baseUrl,
                hideToast: true,
            });

            if (response.status === 404) {
                dispatch(VALIDATION_CREATE_MESSAGE, {
                    type: 'error',
                    message: 'error.professional.not_found',
                });
            }

            if (response.data.status === 200) {
                commit(INVITE_SET_PROFESSIONAL, response.data.data);
            }

            return response;
        },

        async [INVITE_ACCEPT]({ state, commit, dispatch, rootGetters }) {
            if (!state.slug) {
                console.warn('Tried accepting invitation with no pending invitations');
                return;
            }

            const response = await dispatch(REQUEST, {
                method: 'post',
                url: `client/invite-link/${state.slug}/accept`,
                baseURL: rootGetters.baseUrl,
            });

            if (response.status === 200) {
                commit(INVITE_REMOVE);
                commit(INVITE_SET_PROFESSIONAL, response.data.data);
            }

            return response;
        },
    },
};
