<template>
    <div
        v-if="showLandingPage"
        class="landing-page flex min-h-screen flex-col font-marine text-secondary antialiased"
    >
        <main class="flex-grow">
            <router-view :key="$route.fullPath" />
        </main>
        <!--        <cookie-notification></cookie-notification>-->
    </div>
    <div v-else class="theme h-full w-full font-marine font-light text-neutral-darkest antialiased">
        <Navigation v-if="showNavigation" />
        <main v-else class="scrolling-touch h-full w-full overflow-auto">
            <router-view />
        </main>

        <component :is="data.modal" v-for="(data, key) in modals" :key="key" v-bind="data.props" />

        <CookieNotification />
        <ValidationMessages />
        <OfflineNotification />
        <Onboarding v-if="isAuthenticated" />
    </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import Navigation from '@/components/general/Navigation';
import CookieNotification from '@/components/cookies/CookieNotification';
import ValidationMessages from '@/components/general/ValidationMessages';
import { PWAINSTALLPROMPT_EVENT_SET, VALIDATION_CLEAR_BAGS } from './constants';
import Onboarding from '@/components/general/Onboarding';
import OfflineNotification from '@/components/general/OfflineNotification.vue';

Component.registerHooks(['metaInfo']);

@Component({
    components: {
        Navigation,
        CookieNotification,
        ValidationMessages,
        Onboarding,
        OfflineNotification,
    },
})
export default class App extends Vue {
    modals = {};

    metaInfo() {
        return {
            title: this.$t('__title'),
        };
    }

    pwaInstallPromptListener(e) {
        e.preventDefault();
        this.$store.commit(PWAINSTALLPROMPT_EVENT_SET, e);
    }

    created() {
        window.addEventListener('beforeinstallprompt', this.pwaInstallPromptListener);
    }

    async mounted() {
        this.$root.$on('modal-open', ({ modal, props }) => {
            this.$store.commit(VALIDATION_CLEAR_BAGS);
            this.modals = { ...this.modals, [modal.name + modal.cid]: { modal, props } };
        });

        this.$root.$on('modal-close', (cid) => {
            let modalsWithoutItem;
            //if name is given then delete specific modal.
            if (cid) {
                modalsWithoutItem = Object.entries(this.modals).filter(([key]) => key !== cid);
            }
            //if name is not given then delete modal from the top of a stack
            else {
                modalsWithoutItem = Object.entries(this.modals);
                modalsWithoutItem.pop();
            }
            this.modals = Object.fromEntries(modalsWithoutItem);
        });
    }

    beforeDestroy() {
        window.removeEventListener('beforeinstallprompt', this.pwaInstallPromptListener);
    }

    get isThemeClient() {
        return this.$store.getters.isThemeClient;
    }

    get isAuthenticated() {
        return this.$store.getters.isAuthenticated;
    }
    get showLandingPage() {
        return [
            '/',
            '/personal-trainers-and-coaches',
            '/yoga-instructors-and-teachers',
            '/pilates-instructors',
            '/physiotherapists',
        ].includes(this.$route.path);
    }

    get showNavigation() {
        return (
            this.isAuthenticated &&
            ![
                'Home',
                'Bye',
                'PageNotFound',
                'ClientPrivacyPolicy',
                'ClientTermsAndConditions',
                'ProfessionalPrivacyPolicy',
                'ProfessionalTermsAndConditions',
                'ClientAuthReset',
                'ClientAuthActivate',
                'ProfessionalAuthReset',
                'ProfessionalAuthActivate',
                'Unsubscribe',
                'ProfileNotPublic',
            ].includes(this.$route.name)
        );
    }
}
</script>

<style lang="postcss">
@import '~@/styles/index.pcss';
</style>
