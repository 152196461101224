/* eslint-disable no-undef */
import './registerServiceWorker';

import Vue from 'vue';
import App from './App';
import AppMountFailed from './AppMountFailed';
import store from './store';
import i18n from './languages';
import VueMeta from 'vue-meta';
import vMediaQuery from 'v-media-query';
import { connectSentry } from '@/error-reporting';
import { CONFIG_FETCH, SELECTED_LANGUAGE } from '@/constants';
import './icons';
import moment from 'moment';
import VueAnime from 'vue-animejs';
import appRouter from './router/app';
import landingRouter from './router/landing';

Vue.config.productionTip = false;

Vue.use(vMediaQuery);
Vue.use(VueMeta);
Vue.use(VueAnime);

async function main() {
    try {
        await store.dispatch(CONFIG_FETCH, { baseURL: '/' });

        connectSentry(store.state.config.rules);

        var selectedLanguage = localStorage.getItem(SELECTED_LANGUAGE);
        i18n.locale = selectedLanguage ? selectedLanguage : 'en';
        moment.locale(i18n.locale);

        const host = window.location.host;
        const parts = host.split('.');

        let router;

        if (parts.includes('app')) {
            router = appRouter;
        } else {
            router = landingRouter;
        }

        connectAnalytics(router);

        new Vue({
            el: '#app',
            store,
            router,
            i18n,
            render: (h) => h(App),
        });
    } catch (error) {
        console.error(error);
        new Vue({
            el: '#app',
            render: (h) => h(AppMountFailed),
        });
    }
}

main();

function connectAnalytics(usedRouter) {
    let config = store.state.config.rules;
    if (!config.analytics.enabled) return;

    if (config.analytics.id) {
        import('vue-gtag').then((VueGtag) => {
            Vue.use(
                VueGtag.default,
                {
                    config: {
                        id: config.analytics.id,
                    },
                },
                usedRouter,
            );
        });
    }

    if (config.analytics.gtmId) {
        import('@gtm-support/vue2-gtm').then((VueGtm) => {
            Vue.use(VueGtm.default, {
                id: config.analytics.gtmId,
            });
        });
    }

    if (config.analytics.hotjarId) {
        import('vue-hotjar').then((Hotjar) => {
            Vue.use(Hotjar.default, {
                id: config.analytics.hotjarId,
            });
        });
    }
}
